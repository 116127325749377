import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import BreadCrumbs from "../components/breadCrumbs"
import MainColumnSection from "../components/sections/mainColumnBlock"
import WhatWeDoSection from "../components/sections/whatWeDoBlock"
import OurApproachSection from "../components/sections/ourApproachBlock"
import UserPersonasSection from "../components/sections/userPersonasBlock"
import SingleSection from "../components/sections/singleSectionBlock"
import OtherWorkSection from "../components/sections/otherWorksBlock"
import GetInTouch from "../components/GetInTouch"
import Head from '../components/head'

const SingleWorkPage = ({ data }) => {

    const work = data.UI.dsCaseStudies[0]    
    
    return (
        <Layout>
            <Head SEO_Tags={work.SEO_Tags} />
            {work && work.Banner.map((banner, i) => {
                const BannerImage = banner ? banner.Inner_BackgroundImage.url : null
                return BannerImage ? (
                    <section className="section_casestudy_hero tB-padd-70" style={{backgroundImage: "url("+BannerImage+")" }}>
                        <BreadCrumbs crumbs={`Casestudy - ${banner.Inner_Title}`} textColor={banner.Inner_FontColor ? banner.Inner_FontColor : 'text-white'} />
                        <div className="container">
                            <div className="casestudy_banner_table">
                                <div className="casestudy_banner_content" style={{ color: `${banner.Inner_FontColor ? banner.Inner_FontColor : null  }` }}>
                                    <h2 data-sal="slide-right" style={{'--sal-duration':'0.5s', '--sal-delay':'0.10s'}}>{banner.Inner_Title}</h2>
                                    <div dangerouslySetInnerHTML={{ __html: banner.Inner_ShortDescription }} data-sal="slide-right" style={{'--sal-duration':'1s', '--sal-delay':'0.20s'}} />
                                </div>
                            </div>
                        </div>
                    </section>
                ) : null;
            })}

            {work && work.DesignStudioLayouts.map((layout, i) => {
                const TwoColumnImage = layout.LeftImage
                const TwoColumnText= layout.RightText
                const TwoColumnData = [
                  {
                    "TwoBgColor" : layout.TwoColumn_BgColor,
                    "TwoHeading" : layout.TwoColumn_Heading,
                    "LeftImg" : layout.LeftImage ? layout.LeftImage.url : null,
                    "LeftImgAlt" : layout.LeftImage ? layout.LeftImage.alternativeText : null,
                    "RightText" : TwoColumnText
                  }						
                ]

                const weDoTitle = layout.WhatWeDo_Title
                const weDoList = layout.Lists
                const weDoData = [
                  {
                    "weDoBgColor" : layout.WhatWeDo_BgColor,
                    "weDoHeading" : weDoTitle,
                    "weDoDescription" : layout.WhatWeDo_Description
                  }						
                ]

                const Approach_List = layout.ApproachLists
                const Approach_Data = [
                  {
                    "approachBgColor" : layout.Approach_BgColor,
                    "approachHeading" : layout.Approach_Heading,
                    "approachDescription" : layout.Approach_Text
                  }						
                ]

                const Author_List = layout.AuthorLists
                const Author_Title = layout.User_Heading
                const Author_Data = [
                  {
                    "authorBgColor" : layout.User_BgColor,
                    "authorHeading" : Author_Title,
                    "authorDescription" : layout.User_Text
                  }						
                ]
                
                const Single_Title = layout.Heading
                const Single_Description = layout.Description
                const Single_Data = [
                  {
                    "singleBgColor" : layout.BackgroundColor,
                    "singleHeading" : Single_Title,
                    "singleDescription" : Single_Description,
                    "singleImage" : layout.Image ? layout.Image : null,
                    "singleFullWidth" : layout.FullWidth
                  }						
                ]

                const ourWorkTitle = layout.OurWork_Title
                const ourWorkLists = layout.DS_Case_Studies
                const ourWork_Data = [
                  {
                    "ourWorkBgColor" : layout.OurWork_BgColor,
                    "ourWorkHeading" : ourWorkTitle,
                    "ourWorkDescription" : layout.OurWork_Description
                  }						
                ]

                return (
                  <>
                    {TwoColumnImage || TwoColumnImage  ? (
                      <MainColumnSection ColumnData={TwoColumnData} />
                    ) : null}
                    {weDoTitle && weDoList  ? (
                      <WhatWeDoSection weData={weDoData} weList={weDoList} />
                    ) : null}
                    {Approach_List  ? (
                      <OurApproachSection approachData={Approach_Data} approachList={Approach_List} />
                    ) : null}
                    {Author_List && Author_Title  ? (
                      <UserPersonasSection authorData={Author_Data} authorList={Author_List} />
                    ) : null}
                    {Single_Title || Single_Description  ? (
                      <SingleSection singleData={Single_Data} />
                    ) : null}
                    {ourWorkTitle && ourWorkLists  ? (
                      <OtherWorkSection ourWorkData={ourWork_Data} ourWorkList={ourWorkLists} />
                    ) : null}
                  </>
                )
            })}  
            <section className="sectionCTA">
              <GetInTouch />
            </section>  
        </Layout>
    )
}

export default SingleWorkPage

export const query = graphql`
  query page($slug: String!) {
    UI {
        dsCaseStudies(where: {Slug: $slug}) {
            Title
            Slug
            Banner {
                Inner_BackgroundImage {
                    url
                    alternativeText
                }
                Inner_FontColor
                Inner_Title
                Inner_ShortDescription
                CTALink {
                    Button_Name
                    Button_URL
                }
            }     
            DesignStudioLayouts {
              ... on GraphUI_ComponentDesignStudioDsTwoColumnSection {
                id
                TwoColumn_BgColor
                TwoColumn_Heading
                RightText
                LeftImage {
                  url
                  alternativeText
                }
              }
              ... on GraphUI_ComponentDesignStudioWhatWeDoSection {
                id
                WhatWeDo_BgColor
                WhatWeDo_Title
                WhatWeDo_Description
                Lists {
                  WhatWeDo_Heading
                  WhatWeDo_Text
                }
              }
              ... on GraphUI_ComponentDesignStudioSingleSection {
                id
                BackgroundColor
                Heading
                Description
                FullWidth
                Image {
                  url
                  alternativeText
                }
              }
              ... on GraphUI_ComponentDesignStudioDsApproachSection {
                id
                Approach_BgColor
                Approach_Heading
                Approach_Text
                ApproachLists {
                  Approach_Title
                  Approach_Description
                  Approach_IconNum {
                    url
                    alternativeText
                  }
                }
              }
              ... on GraphUI_ComponentDesignStudioDsUserPersonSection {
                id
                User_BgColor
                User_Heading
                User_Text
                AuthorLists {
                  Author_BgColor
                  FontColor
                  Author_Img {
                    url
                    alternativeText
                  }
                  Author_Name
                  Author_Age
                  Author_Gender
                  Author_Profession
                  Author_Experience
                  Author_Description
                  Author_Painpoints
                  Author_Goals
                }
              }
              ... on GraphUI_ComponentDesignStudioOurWorkSection {
                id
                OurWork_BgColor
                OurWork_Title
                OurWork_Description
                DS_Case_Studies {
                  Title
                  Slug
                  ShortDescription
                  FeaturedImage {
                    url
                    alternativeText
                  }
                }
              }
            }
            SEO_Tags {
              Meta_Title
              Meta_Description
            }
        }
    }
}`