import * as React from "react"

const OUTAPPROACHSECTION = ({approachData, approachList}) => {
    return(
        <section className="BG-F9FAFE section_approach_pointer" style={{backgroundColor: `${approachData[0].approachBgColor ? approachData[0].approachBgColor : null  }`}}>
            <div className="container">
                {approachData[0].approachHeading ? (
                    <h2 className="h2Bluetitle B_margin_30">{approachData[0].approachHeading}</h2>
                ) :null}

                {approachData[0].approachDescription ? (
                    <p dangerouslySetInnerHTML={{ __html: approachData[0].approachDescription }} />
                ) :null}
                <div className="row justify-content-center">
                    {approachList && approachList.map((list, i) => ( 
                        <div className="col-md-10">
                            <div className="approach_pointers" data-sal="slide-up" style={{'--sal-duration':`0.${i}s`, '--sal-delay':`0.${i}s`}}>
                                <div className="approach_pointer_inner">
                                    {list.Approach_IconNum ? (
                                        <img src={list.Approach_IconNum.url} alt={list.Approach_IconNum.alternativeText} />
                                    ) : null}
                                    <div className="approach_content_container">
                                        <div className="approach_title">
                                            {list.Approach_Title ? (
                                                <h3>{list.Approach_Title}</h3>
                                            ) : null}
                                        </div>
                                        <div className="approach_content">
                                            <p className="lato_reg_18" dangerouslySetInnerHTML={{ __html: list.Approach_Description }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default OUTAPPROACHSECTION