import * as React from "react"

const SINGLESECTION = ({singleData}) => {
    
    return(
        <section className="BG-F9FAFE section_user-flow tB-padd-40" style={{backgroundColor: `${singleData[0].singleBgColor ? singleData[0].singleBgColor : null  }`}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        {singleData[0].singleHeading ? (
                            <h4 className="h4_medium_28_ubuntu blueColorTitle B_margin_30" data-sal="slide-left" style={{'--sal-duration':`0.1s`, '--sal-delay':`0.1s`}}>{singleData[0].singleHeading}</h4>
                        ) : null}
                        {singleData[0].singleDescription ? (
                            <p className="lato_reg_18 B_margin_30" dangerouslySetInnerHTML={{ __html: singleData[0].singleDescription }} style={{maxWidth:'500px', width:'100%'}} data-sal="slide-left" style={{'--sal-duration':`0.4s`, '--sal-delay':`0.4s`}} />
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="text-center">
                {singleData[0].singleImage.length > 1 ? (
                    <>
                        {singleData[0].singleImage.map((img, i) => ( 
                            <div className="tB-padd-20" key={i} data-sal="slide-right" style={{'--sal-duration':`0.${i}s`, '--sal-delay':`0.${i}s`}}>
                                <img src={img.url} alt={img.alternativeText} className={singleData[0].singleFullWidth ? "img-fluid img_width_100" : "img-fluid" } />
                            </div>
                        ))}
                    </>
                ) : (
                    <>
                        {singleData[0].singleImage.map((img, i) => ( 
                            <div className="tB-padd-20" key={i}>
                                <img src={img.url} alt={img.alternativeText} className={singleData[0].singleFullWidth ? "img-fluid img_width_100" : "img-fluid" } />
                            </div>
                        ))}
                    </>
                )}
            </div>
        </section>
    )
}

export default SINGLESECTION