import * as React from "react"
import { Link } from "gatsby"
import { makeWorkSingleSlug } from "../../utils"

const OTHERTWORKSECTION = ({ourWorkData, ourWorkList}) => {
    
    return(
        <section className="BG-F9FAFE section_allWork" style={{backgroundColor: `${ourWorkData[0].ourWorkBgColor ? ourWorkData[0].ourWorkBgColor : null  }`}}>
            <div className="container">
                {ourWorkData[0].ourWorkHeading ? (
                    <h2 className="h2Bluetitle B_margin_30" data-sal="slide-right" style={{'--sal-duration':`0.1s`, '--sal-delay':`0.1s`}}>{ourWorkData[0].ourWorkHeading}</h2>
                ) :null}

                {ourWorkData[0].ourWorkDescription ? (
                    <p dangerouslySetInnerHTML={{ __html: ourWorkData[0].ourWorkDescription }} data-sal="slide-right" style={{'--sal-duration':`0.4s`, '--sal-delay':`0.4s`}} />
                ) :null}

                <div className="row justify-content-center">
                    {ourWorkList && ourWorkList.map((list, i) => ( 
                        <div className="col-md-6 col-sm-6 otherWork_left_col ourWork_col">
                            <Link exact to={makeWorkSingleSlug(list)} data-sal="slide-down" style={{'--sal-duration':`0.1${i}s`, '--sal-delay':`0.1${i}s`}}>
                                <div className="ourworkColInner">
                                    {list.FeaturedImage ? (
                                        <img src={list.FeaturedImage.url} alt={list.FeaturedImage.alternativeText} />
                                    ) : null}
                                    {list.Title ? (
                                        <h4>{list.Title}</h4>
                                    ) : null}
                                    <div className="all_workTilecontent">
                                        <p dangerouslySetInnerHTML={{ __html: list.ShortDescription }} />
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default OTHERTWORKSECTION