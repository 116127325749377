import * as React from "react"

const MAINCOLUMNHSECTION = ({ColumnData}) => {
    
    return(
        <section className="BG-F9FAFE section_challenges tB-padd-50" style={{backgroundColor: `${ColumnData[0].TwoBgColor ? ColumnData[0].TwoBgColor : null  }`}}>
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-md-6">
                        <div className="div_challenge_img" data-sal="slide-right" style={{'--sal-duration':'0.5s', '--sal-delay':'0.10s'}}>
                            {ColumnData[0].LeftImg ? (
                                <img src={ColumnData[0].LeftImg} alt={ColumnData[0].LeftImgAlt} />
                            ) : null}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="div_challenge_content" data-sal="slide-left" style={{'--sal-duration':'1s', '--sal-delay':'0.10s'}}>
                            <h2 className="h2Bluetitle B_margin_20">{ColumnData[0].TwoHeading}</h2>
                            <div className="lato_reg_18" dangerouslySetInnerHTML={{ __html: ColumnData[0].RightText }} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MAINCOLUMNHSECTION