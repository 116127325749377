import * as React from "react"

const USERPERSONSECTION = ({authorData, authorList}) => {
    
    return(
        <section className="BG-F9FAFE section_user_persona tB-padd-40" style={{backgroundColor: `${authorData[0].authorBgColor ? authorData[0].authorBgColor : null  }`}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        {authorData[0].authorHeading ? (
                            <h4 className="h4_medium_28_ubuntu blueColorTitle B_margin_30"  data-sal="slide-right" style={{'--sal-duration':'1s', '--sal-delay':'0.15s'}}>{authorData[0].authorHeading}</h4>
                        ) :null}

                        {authorData[0].authorDescription ? (
                            <p dangerouslySetInnerHTML={{ __html: authorData[0].authorDescription }} />
                        ) :null}
                        {authorList && authorList.map((list, i) => ( 
                            <div className="twoColorCardMain">
                                <div className="twoColorCardInner">
                                    <div className="twocolorCard_leftCol" style={{backgroundColor: `${list.Author_BgColor ? list.Author_BgColor : null  }`, color : `${list.FontColor ? list.FontColor : null}`}}>
                                        <div className="cardImg text-center">
                                            {list.Author_Img ? (
                                                <img src={list.Author_Img.url} alt={list.Author_Img.alternativeText} style={{borderColor: `${list.Author_BgColor ? list.Author_BgColor : null  }`}} />
                                            ) : null}
                                        </div>
                                        <div className="personInformation">
                                            <div className="cardName text-center">
                                                {list.Author_Name ? (
                                                    <h5>{list.Author_Name}</h5>
                                                ) : null}
                                            </div>
                                            <div className="cardInfo_short">
                                                <ul>
                                                    {list.Author_Age ? (
                                                        <li>Age : {list.Author_Age}</li>
                                                    ) : null}
                                                    {list.Author_Gender ? (
                                                        <li>Gender : {list.Author_Gender}</li>
                                                    ) : null}
                                                    {list.Author_Profession ? (
                                                        <li>Profession : {list.Author_Profession}</li>
                                                    ) : null}
                                                    {list.Author_Experience ? (
                                                        <li>Experience : {list.Author_Experience}</li>
                                                    ) : null}
                                                </ul>
                                                <p dangerouslySetInnerHTML={{ __html: list.Author_Description }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="twocolorCard_rightCol" style={{backgroundColor: '#f0f0f0'}}>
                                        <div className="card_genral_info">
                                            {list.Author_Painpoints ? (
                                                <>
                                                    <h5>Painpoints</h5>
                                                    {list.Author_BgColor ? (
                                                        <style>.card_genral_info ul li::before {`{color:${list.Author_BgColor}}`}</style>
                                                    ) : null}
                                                    <div dangerouslySetInnerHTML={{ __html: list.Author_Painpoints }} />
                                                </>
                                            ) : null}
                                            {list.Author_Goals ? (
                                                <>
                                                    <h5>Goals</h5>
                                                    {list.Author_BgColor ? (
                                                        <style>.card_genral_info ul li::before {`{color:${list.Author_BgColor}}`}</style>
                                                    ) : null}
                                                    <div dangerouslySetInnerHTML={{ __html: list.Author_Goals }} />
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}                        
                    </div>
                </div>
            </div>
        </section>
    )
}

export default USERPERSONSECTION