import * as React from "react"

const WhATWEDOSECTION = ({weData, weList}) => {
    
    return(
        <section className='BG-F9FAFE section_our_approach tB-padd-30' style={{backgroundColor: `${weData[0].weDoBgColor ? weData[0].weDoBgColor : null  }`}}>
            <div className="container">
                {weData[0].weDoHeading ? (
                    <h2 className="h2Bluetitle B_margin_30">{weData[0].weDoHeading}</h2>
                ) :null}

                {weData[0].weDoDescription ? (
                    <p dangerouslySetInnerHTML={{ __html: weData[0].weDoDescription }} />
                ) :null}
                
                <div className="row justify-content-center">
                    {weList && weList.map((list, i) => (
                        <div className="col-md-3 ourApproachCol tB-padd-20" data-sal="slide-right" style={{'--sal-duration':`0.5${i}s`, '--sal-delay':`0.10${i}s`}}>
                            {list.WhatWeDo_Heading ? (
                                <h4>{list.WhatWeDo_Heading}</h4>
                            ) : null}
                            {list.WhatWeDo_Text ? (
                                <div dangerouslySetInnerHTML={{ __html: list.WhatWeDo_Text }} />
                            ) :null}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default WhATWEDOSECTION